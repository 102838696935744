<template>

  <div class="cash">
    <i
        @click="$router.push('/admin_panel')"
        class="mdi mdi-folder-plus"></i>
    <div class="products">


      <h3 class="mb-2">Категории</h3>
      <div
          class="cash__categories mb-4"
      >
        <div
            v-for="(category,category_index) in categories" :key="category_index"
            :class="{'cash__categories--item' :true, 'cash__categories--active': category_id === category.id  }"
            @click="getProductsByCategory(category.id)"
        >

          <p>{{ category.name }} </p>

        </div>

      </div>
      <p class="good__name">Товары</p>


      <input
          v-model="search"
          class="good__search"
          type="text"
          placeholder="Поиск..."
      >


      <div class="products__menu">
        <div

            class="product__item"

            v-for="(product,product_index) in products"
            :key="product_index"

            @click="addOrder(product)"
        >
          {{ product.name }}
        </div>
      </div>

      <v-pagination
          v-if="pageCount"
          v-model="page"
          :length="pageCount"
          :total-visible="3"
      ></v-pagination>
    </div>


    <div class="cassa">

      <div
          v-for="(order,i) in orders" :key="i"
          class="product__added">

        <div
            class="product__order--item"
        >
          <p><b>Название:</b>&nbsp;{{ order.name }}</p>
          <p class="mb-4"><b>Цена:</b>&nbsp;{{ order.price }}
            <span v-if="order.count_type === 'count'">тнг/шт</span>
            <span v-else-if="order.count_type === 'kg'">тнг/кг</span>
            <span v-else-if="order.count_type === 'litr'">тнг/л</span>
          </p>

          <div
              v-if="order.count_type === 'count'"
              class="product__action">
            <div
                @click="subOrderCount(order)"
                class="product__plus">-
            </div>

            <p>{{ order.count_value ? order.count_value : 1 }}</p>
            <div
                @click="addOrderCount(order)"
                class="product__plus">+
            </div>
          </div>
          <div
              v-else
              class="product__action">
            <input placeholder="кг|литр" @input="changeMass(order)" v-model="order.amount_value"
                   type="number">
          </div>



          <p  v-if="order.order_price">{{ order.order_price }} тнг</p>


          <div
              @click="deleteElement(order.id)"
              class="product__delete"
          >
            <i class="mdi mdi-delete-forever"></i>
          </div>
        </div>

      </div>

      <div>

      </div>


      <div>
        <div class="payment__types mb-4">
          <v-select
              :items="payment_types"
              item-text="label"
              item-value="value"
              label="Выберите тип оплаты"
              v-model="selected_payment"
              outlined
          ></v-select>
        </div>
        <div class="payment mb-4">
          <p>К оплате </p>
          <p>{{ amount_of_order }} tg</p>
        </div>
        <div
            @click="saveOrder"
            class="payment"
        >
          <p>Оплатить</p>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import {debounce} from 'lodash';
export default {
  name: "CashInterface",
  data() {
    return {
      pageCount: 0,
      page: 1,
      categories: [],
      payment_types: [],
      orders: JSON.parse(localStorage.getItem('orders')) || [], // Initialize properly
      products: [],
      selected_payment: '',
      amount_of_order: '',
      search: ''
    }
  },
  mounted() {
    this.getPayments()
    this.getCategories()
  },
  methods: {
    onDecode(result) {
      console.log('Decoded Result:', result);
    },
    onLoaded(result) {
      console.log("rr",result)
    },
    debounceSearchProduct: debounce(function () {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?search=${this.search}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    }, 1000),
    searchProduct() {

      this.debounceSearchProduct();

    },

    // checkQuantity() {
    //
    //   this.$axios({
    //     method: 'post',
    //     url: `${this.$API_URL}check/quantity`,
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    //     },
    //     data: data
    //   })
    //       .then((res) => {
    //         this.$toast.open({
    //           message: res.data.message,
    //           type: "warning",
    //           position: "bottom",
    //           duration: 5000,
    //           queue: true,
    //         });
    //         this.selected_payment = ''
    //         this.orders = []
    //       })
    //       .catch((error) => {
    //         console.log(error)
    //       });
    // },
    saveOrder() {

      const hasZeroAmount = this.orders.some(order => order.count_type !== 'count' && order.amount_value === 0);

      if (hasZeroAmount) {
        this.$toast.open({
          message: 'Укажите вес или литр',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      }
      if (!this.selected_payment) {
        this.$toast.open({
          message: 'Выберите тип оплаты',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });

      }


      let data = {
        orders: this.orders,
        total: this.amount_of_order,
        payment_type: this.selected_payment
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}order`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.selected_payment = ''
            this.orders = []
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getAmountOfOrder() {
      console.log("result", this.orders)
      this.amount_of_order = this.orders.reduce((total, order) => total + order.order_price ?? 0, 0);

    },
    deleteElement(id) {
      const index = this.orders.findIndex(order => order.id === id);
      if (index !== -1) {

        for (let i = 0; i < this.products.length; i++) {
          this.products[i].count_value = 1
          this.products[i].order_price = parseInt(this.products[i].price)
          this.products[i].amount_value = 0
        }

        this.orders.splice(index, 1);
      }
    },
    changeMass(order) {
      console.log(order)
      this.$set(order, 'order_price', order.amount_value * order.price);
    },
    addOrderCount(order) {

      if (order.quantity === 0 || order.quantity < 0)
        return false;
      if (order.quantity === 1) {
        this.$set(order, 'count_value', 1);
        return false;
      }
      if (!order.count_value) {
        this.$set(order, 'count_value', 2);
        return false;
      } else {
        order.count_value = order.count_value + 1
      }

      order.order_price = order.count_value * order.price
    },
    subOrderCount(order) {

      if (!order.count_value) {
        this.$set(order, 'count_value', 1);
        return false;
      }
      else if (order.count_value <= 1) {
        order.count_value = 1
        return false;
      }
      else {
        order.count_value = order.count_value - 1
      }



      order.quantity = order.quantity - order.count_value
      order.order_price = order.order_price - 1 * order.price
    },
    getProductsByCategory(id, page = null) {
      this.category_id = id
      if (!page)
        page = 1

      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?category_id=${id}&page=${page}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getCategories() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.categories = res.data
          })
          .catch((error) => {
            console.log(error)
          });
    },
    addOrder(product) {


      this.$set(product, 'amount_value', 0);
      this.$set(product, 'count_value', 1);
      if (!product.order_price) {
        if (product.count_type === 'count')
          this.$set(product, 'order_price', parseInt(product.price));
        else
          this.$set(product, 'order_price', 0);
      }


      if (!this.orders.some(order => order.id === product.id)) {
        this.orders.push(product)
      }


    },
    getPayments() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}payments`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            console.log(res.data)
            this.payment_types = res.data
            console.log(this.payment_types)
          })
          .catch((error) => {
            console.log(error)
          });
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.searchProduct();
      } else {
        this.getProductsByCategory(this.category_id, 1);
      }
    },
    page(newPage) {
      this.getProductsByCategory(this.category_id, newPage);
    },
    orders: {
      handler() {
        console.log('Orders array changed');
        console.log('New total order price:', this.getAmountOfOrder());

        localStorage.setItem('orders', JSON.stringify(this.orders))
      },
      deep: true,
      immediate: true
    }
  }

};
</script>

<style>

p {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.cash {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;

  .products {
    width: 50%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;
    padding: 20px;
    height: 100vh;

    .good__name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px !important;
    }

    .good__search {
      padding: 10px;
      border: 1px solid black;
      margin-bottom: 20px;
    }


  }

}

.cassa {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 20px;
  height: 100vh;
  overflow-y: scroll;

  .product__added {
    margin-bottom: 20px;
    position: relative;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .product__name {
      margin-bottom: 10px !important;
      font-weight: bold;
    }

    .product__action {
      width: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px !important;


      input {
        border-radius: 5px;
        padding: 5px;
        border: 1px solid black;
        width: 100px;
      }

      .product__plus {
        cursor: pointer;
        padding: 10px;
        background: #0069ff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 5px;
        font-size: 18px;
      }
    }
  }
}


.payment {
  cursor: pointer;
  background: #0069ff;
  padding: 20px;
  border-radius: 5px;
  color: white;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.payment__types {

  .payment__type--title {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    padding: 5px !important;
    margin-bottom: 2px !important;

  }
}

.product__delete {

  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  i {
    font-size: 18px;
    color: red;
  }
}

.product__order--item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}


.cash__categories::-webkit-scrollbar {
  width: 1px;
}

.cash__categories::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
}

.cash__categories::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.cash__categories {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;

  .cash__categories--item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    padding: 30px;
    border-radius: 5px;

    border: 1px solid #0069ff;
  }
}

.products__menu {
  display: flex;
  flex-wrap: wrap;

  .product__item {
    padding: 20px;
    cursor: pointer;
    border-radius: 5px;

    border: 1px solid #ccc;
    margin-right: 10px;
    width: 100px;
  }
}

.cash__categories--active {
  background: #ffc64a;
  color: white;
}
</style>